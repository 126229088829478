import { ArrisKeyMapModel } from '@kuki/global/shared/types/controller/keymap';
import { toiVersion } from '@kuki/platforms/tv/arris/toi-version';

export class ArrisKeyMap implements ArrisKeyMapModel {
    UP = 38;
    DOWN = 40;
    LEFT = 37;
    RIGHT = 39;

    CH_UP = 917552;
    CH_DOWN = 917553;

    OK = 13;
    BACK = 917536;

    PLAY = 917797;
    PAUSE = null;
    STOP = 917522;
    REWIND = 917523;
    FORWARD = 917524;
    PREV = [ 917525, 80 ];
    NEXT = [ 917526, 78 ];

    REC = 917527;

    N_0 = 48;
    N_1 = 49;
    N_2 = 50;
    N_3 = 51;
    N_4 = 52;
    N_5 = 53;
    N_6 = 54;
    N_7 = 55;
    N_8 = 56;
    N_9 = 57;

    COLOR_RED = 917504;
    COLOR_GREEN = 917505;
    COLOR_YELLOW = 917506;
    COLOR_BLUE = 917507;

    // Platform specific
    TXT = [ '917777', '84' ];
    INFO = 917556;
    EPG = 917776; // tools key
    MUTE = [ 917744, 77 ];
    VOLUME_UP = [ 917747, 85 ];
    VOLUME_DOWN = [ 917748, 68 ];
    // matching remote
    TV = toiVersion === 3 ? 917555 : 917554;
    // cross remote
    TV2 = toiVersion === 3 ? 917555 : 917783;
    // matching remote
    MENU = toiVersion === 3 ? 917783 : 917555;
    // cross remote
    MENU2 = toiVersion === 3 ? 917554 : 917555;
    SEARCH = 917792;
    PAUSEPLAY_COMBO = 917528;
    VOD = 917778;
    POWERBTN = 917557;

    // PC
    PC_BACK = 8;
    PC_TURN_ON = 73;
    PC_TURN_OFF = 79;
    PC_REC = 82;
    PC_MENU = 77;
    PC_EPG = 69;

    // GRP
    GRP_BACK = [ this.BACK, this.PC_BACK ];

    GRP_MENU = [ this.MENU, this.MENU2, this.PC_MENU ];

    GRP_INFO = [ this.INFO ];

    GRP_TV = [ this.TV, this.TV2, this.PC_TURN_ON ];

    GRP_NUM_KEY = [
        this.N_0, this.N_1, this.N_2, this.N_3, this.N_4,
        this.N_5, this.N_6, this.N_7, this.N_8, this.N_9
    ];

    GRP_EPG = [ this.EPG, this.PC_EPG ];

    GRP_REC = [ this.REC, this.COLOR_RED, this.PC_REC ];

    GRP_ARROW = [ this.UP, this.DOWN, this.LEFT, this.RIGHT ];

    GRP_PLAY = [ this.PLAY, this.PAUSEPLAY_COMBO ];

}
