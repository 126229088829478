import { Inject, Injectable } from '@angular/core';
import { toiVersion } from '@kuki/platforms/tv/arris/toi-version';
import { ArrisPlatformHalService } from '@kuki/platforms/tv/arris/arris-platform-hal.service';
import { Plugin } from '@kuki/global/shared/types/general';
import { SettingsService } from '@kuki/global/shared/services/settings.service';

declare var toi;

@Injectable()
export class ResolutionSwitchService implements Plugin {

    private ignoreChangedEvents: boolean = false;
    private displayChangedHandlerListener;
    private currentVideoMode = -1;

    constructor(
        @Inject('PlatformHalService') private arrisPlatformHalService: ArrisPlatformHalService,
        private settingsService: SettingsService) {
    }

    public init() {
        if (this.settingsService.getSettingsValue('videoMode') === null) {
            this.settingsService.storeSettingsKey('videoMode', this.arrisPlatformHalService.getDefaultVideoMode()).subscribe();
        }
        this.setVideoOutput();
    }

    private displayChangedHandler() {
        if (this.ignoreChangedEvents) {
            console.log('HDMI display changed event - ignoring');
            return;
        }

        this.ignoreChangedEvents = true;

        console.log('HDMI display changed event - reconfigure output');
        setTimeout(() => {
            this.setVideoOutput();
        }, 1000);
    }

    public setVideoOutput() {
        console.log('setVideoOutput() running');
        let videoConfiguration;
        const vos = toi.videoOutputService;
        if (toiVersion === 2) {
            videoConfiguration = vos.getVideoConfiguration();
            // rebind listener
            if (this.displayChangedHandlerListener) {
                try {
                    videoConfiguration.removeEventListener(
                        toi.consts.ToiVideoOutputConfiguration.ON_DISPLAY_CHANGED,
                        this.displayChangedHandlerListener);
                } catch (e) {
                    console.log('HDMI display listener not defined yet');
                }
            }
            this.displayChangedHandlerListener = this.displayChangedHandler.bind(this);
            videoConfiguration.addEventListener(
                toi.consts.ToiVideoOutputConfiguration.ON_DISPLAY_CHANGED,
                this.displayChangedHandlerListener);
        } else {
            videoConfiguration = vos.getConfiguration();
        }
        const vc = {
            'SCART': null,
            'HDMI': null,
        };
        const videoOutputs = toiVersion === 2 ? videoConfiguration.getVideoOutputs() : videoConfiguration.getOutputs();
        videoOutputs.forEach(videoOutputId => {
            const displayInfo = toiVersion === 2 ?
                videoConfiguration.getVideoOutputInfo(videoOutputId) : videoConfiguration.getOutputInfo(videoOutputId);
            switch (displayInfo.connectionType) {
                case videoConfiguration.VIDEO_CONNECTION_TYPE_HDMI:
                    vc[ 'HDMI' ] = videoOutputId;
                    break;
                case videoConfiguration.VIDEO_CONNECTION_TYPE_SCART:
                    vc[ 'SCART' ] = videoOutputId;
                    break;
            }
        });

        let session = null;

        try {
            const settings = this.settingsService.getSettings();
            const reqColorSystem = parseInt(settings.colorSystem, 10);
            const reqColorSpace = parseInt(settings.colorSpace, 10);
            const reqAspect = parseInt(settings.aspect, 10);
            const reqScartMode = parseInt(settings.scartMode, 10);
            const reqVideoMode = parseInt(settings.videoMode, 10);

            if (toiVersion === 2) {
                session = vos.createVideoConfigurationSession();
                session.setColorSystem(reqColorSystem);
            } else {
                session = vos.createSession();
                session.configureColorSystem(reqColorSystem);
            }
            if (vc[ 'SCART' ] !== null) {
                session.setDisplayInfo(vc[ 'SCART' ], this.arrisPlatformHalService.getVideoModes(), reqAspect);
                session.setScartMode(vc[ 'SCART' ], reqScartMode);

                let loopthrough = false;
                videoConfiguration.getVideoOutputCapabilityInfo(session.VIDEO_CONNECTION_TYPE_SCART)
                    .scartLoopThroughModes.forEach(lmode => {
                    if (lmode === session.VALUE_MODE_ENABLED) {
                        loopthrough = true;
                        return;
                    }
                });
                if (loopthrough) {
                    session.setScartLoopThroughMode(vc[ 'SCART' ], session.VALUE_MODE_ENABLED);
                }
            }
            if (vc[ 'HDMI' ] !== null) {
                if (toiVersion === 2) {
                    session.resetDisplayInfo(vc[ 'HDMI' ]);
                    const di = session.getDisplayInfo(vc[ 'HDMI' ]);

                    try {
                        // print(''.join([chr(ord('A') - 1 + ((x >> 16) >> (i * 5) & (0b11111)))
                        // for i in range(3)][::-1])) - decode vendor ID
                        console.log(`HDMI display vendor: ${ di.vendorId }`);
                    } catch (e) {
                        console.log('HDMI display vendor: unknown');
                    }
                    try {
                        console.log(`HDMI status: ${ di.status }`);
                    } catch (e) {
                        console.log('HDMI status: unknown');
                    }
                    const origSupVideoModes = di.supportedVideoModes
                        .filter((x) => x !== toi.consts.ToiVideoOutputConfiguration.NO_VIDEO_MODE)
                        .map((y) => parseInt(y, 10)).sort((a, b) => a - b).reverse();
                    const ourVideoModes = this.arrisPlatformHalService.getVideoModes();
                    const supVideoModes = origSupVideoModes.filter((value) => ourVideoModes.indexOf(value) !== -1);
                    const supColorSpaces = [ ...di.supportedColorSpaces ];

                    console.log(`HDMI supportedModes: ${ origSupVideoModes.join(', ') }`);
                    console.log(`HDMI filteredSupportedModes: ${ supVideoModes.join(', ') }`);
                    console.log(`HDMI reqMode: ${ reqVideoMode }`);

                    session.setDisplayInfo(vc[ 'HDMI' ], di.supportedVideoModes, reqAspect);

                    let myVideoMode = reqVideoMode;

                    if (supVideoModes.indexOf(reqVideoMode) >= 0) {
                        console.log(`HDMI // setting required video mode: ${ reqVideoMode }`);
                    } else {
                        console.log('HDMI // NOT setting required video mode');
                        if (supVideoModes.length) {
                            console.log(`HDMI // setting first video mode: ${ supVideoModes[ 0 ] }`);
                            myVideoMode = supVideoModes[ 0 ];
                        } else {
                            console.log('HDMI // setting 576P50 default video mode');
                            myVideoMode = toi.consts.ToiVideoOutputConfiguration.VIDEO_MODE_576P50;
                        }
                    }

                    if (this.currentVideoMode != myVideoMode) {
                        console.log(`HDMI // really applying myVideoMode ${ myVideoMode }`);
                        session.setDefaultVideoMode(vc[ 'HDMI' ], myVideoMode);
                        this.currentVideoMode = myVideoMode;

                        if (supColorSpaces.indexOf(reqColorSpace) >= 0) {
                            console.log('HDMI // setting required colorspace');
                            session.setColorSpace(vc[ 'HDMI' ], reqColorSpace);
                        } else {
                            console.log('HDMI // NOT setting required colorspace');
                            if (supColorSpaces.length) {
                                console.log('HDMI // NOT setting required colorspace - setting first available');
                                session.setColorSpace(vc[ 'HDMI' ], supColorSpaces[ 0 ]);
                            } else {
                                console.log('HDMI // NOT setting required colorspace - setting mode 1');
                                session.setColorSpace(vc[ 'HDMI' ], 1);
                                session.setDviMode(vc[ 'HDMI' ], session.VALUE_MODE_DISABLED);
                            }
                        }

                        session.setDviMode(vc[ 'HDMI' ], session.VALUE_MODE_DISABLED);

                    } else {
                        console.log(`HDMI // NOT applying myVideoMode ${ myVideoMode }, the same as currentVideoMode ${ this.currentVideoMode }`);

                        setTimeout(() => {
                            this.ignoreChangedEvents = false;
                        }, (myVideoMode === toi.consts.ToiVideoOutputConfiguration.VIDEO_MODE_576P50 ? 20000 : 1000));

                        session.releaseInstance();
                        return;
                    }

                } else {
                    const di = session.getDisplayCapabilities(vc[ 'HDMI' ]);
                    const supVideoModes = [ ...di.hdmi.supportedModes ];
                    const supColorSpaces = [ ...di.hdmi.supportedColorSpaces ];

                    let myVideoMode = reqVideoMode;

                    console.log(`reqVideoMode: ${ reqVideoMode }`);
                    console.log(`supportedModes: ${ supVideoModes.join(', ') }`);
                    if (supVideoModes.indexOf(reqVideoMode) === -1) {
                        console.log('HDMI // NOT setting required default video mode');
                        if (supVideoModes.length) {
                            console.log('HDMI // setting first video mode');
                            myVideoMode = supVideoModes[ 0 ];
                        } else {
                            console.log('HDMI // setting 576P50 default video mode');
                            myVideoMode = toi.consts.ToiVideoOutputConfiguration.VIDEO_MODE_576P50;
                        }
                    }
                    console.log(vc[ 'HDMI' ]);
                    console.log('mode ' + myVideoMode);
                    console.log('aspectRatio: ' + reqAspect);

                    session.configure(vc[ 'HDMI' ], {
                        'hdmi': {
                            'mode': myVideoMode,
                            'aspectRatio': reqAspect,
                        }
                    });
                }
            }
            if (toiVersion === 2) {

                setTimeout(() => {
                    this.ignoreChangedEvents = false;
                }, 1000);

                session.apply();
                session.releaseInstance();
            } else {
                session.apply(() => {
                });
                session.releaseInstance();
            }
        } catch (e) {
            if (session !== null) {
                session.releaseInstance();
            }
            console.log(e);
        }
    }
}
