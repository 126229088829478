import { DeviceTypes } from '@kuki/global/shared/types/device';
import { ArrisKeyMap } from '@kuki/platforms/tv/arris/keymap';

export const hal = {
    appMode: 'TV',
    deviceType: DeviceTypes.STB,
    platform: 'TV.ARRIS',
    keymap: ArrisKeyMap,
    animationType: 'fixed',
    afrTimeout: 4 * 60 * 60 * 1000,
    wsLogging: false,
    mediaPlayer: {
        mediaPlayerHal: 'KREATV',
        playbackUrlType: 'cached',
        playbackUrlFmt: null,
        playbackUrlBaseType: 'baseUrlPlainCached',
        dolbyDigital: true,
        fpsInt: false,
        initOnStartup: false,
        chromecastReceiverAppId: null,
        airplay: false,
        epd: true
    },
    volume: {
        volumeMin: 0,
        volumeMax: 100,
        volumeSteps: 10,
        defaultVolume: 20,
        defaultMute: false,
        setVolumeOnBoot: true
    },
    cap: {
        connectionCheck: true,
        connectionCheckWifi: false,
        upgrader: true,
        universalLinks: false,
        watchDog: true,
        watchDogUrl: 'http://127.0.0.1:7744/watchdog',
        hdmioff: true,
        tvoff: true,
        videoOutputSettings: true,
        audioPassthrough: true,
        afr: true,
        mcast: true,
        rebootOnConnectionLost: true,
        powerupOnConnectionLost: false,
        ga: true,
        testTimeOnStartup: false,
        wakeOnRemote: true,
        sleepOnRemote: true,
        teleport: true,
        volumeControl: true
    },
};
